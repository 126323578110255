.learning-list {
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.faq-transition-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.faq-transition-enter-active {
  max-height: 100px;
  /* Adjust based on content */
  opacity: 1;
}

.faq-transition-exit {
  max-height: 100px;
  /* Adjust based on content */
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.faq-transition-exit-active {
  max-height: 0;
  opacity: 0;
}