.faq-transition-enter {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
}

.faq-transition-enter-active {
  opacity: 1;
  max-height: 1000px; /* Adjust based on your content */
}

.faq-transition-exit {
  opacity: 1;
  max-height: 1000px; /* Adjust based on your content */
}

.faq-transition-exit-active {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
}