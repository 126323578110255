.neuron-background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../public/programs/neuron.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

.sticky-toc {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 50px; /* Adjust this value based on your needs */
    height: calc(100vh - 20px); /* Optional: Adjust the height */
    overflow-y: auto; /* Optional: Adds scroll to the TOC if it's taller than the viewport */
  }

.section {
  position: relative;
}

.section::before {
  content: "";
  display: block;
  height: 20rem; /* Adjust this value based on your navbar height */
  margin-top: -20rem; /* Adjust this value based on your navbar height */
  visibility: hidden;
}